<template>
  <div>
      <div v-if="getLoaded()" class="duck-form form-width-s3 form-center">
        <form @submit.prevent="approveAuction">
          <fieldset class="form-block">
            <div class="form-container">
              <SmallTitle title="ข้อมูลโครงการ" />
              <div class="data-2col">
                <div class="row">
                  <div class="title">รหัสโครงการ</div>
                  <div class="detail">
                    {{ project.code }} - <router-link :to="'/project/edit/'+project.PID">view</router-link>
                  </div>
                </div>
                <div class="row">
                  <div class="title">ชื่อโครงการ</div>
                  <div class="detail">{{ project.name }}</div>
                </div>
                <div class="row">
                  <div class="title">ประเภทงาน</div>
                  <div class="detail">{{ project.cat | projectCatIdToName }}</div>
                </div>
              </div>
              
              <SmallTitle title="ข้อมูลสมาชิก" />
              <div class="data-2col">
                <div class="row">
                  <div class="title">ชื่อ-นามสกุล</div>
                  <div class="detail">
                    {{ member.first_name }} {{ member.last_name }} - <router-link :to="'/member/edit/'+member.MID">view</router-link>
                  </div>
                </div>
                <div v-if="member.business_type==9" class="row">
                  <div class="title">ชื่อบริษัท</div>
                  <div class="detail">{{ member.company_name }}</div>
                </div>
                <div class="row">
                  <div class="title">โทรศัพท์</div>
                  <div class="detail"><a :href="'tel:'+member.phone">{{ member.phone }}</a></div>
                </div>
                <div class="row">
                  <div class="title">อีเมล์</div>
                  <div class="detail"><a :href="'mailto:'+member.email">{{ member.email }}</a></div>
                </div>
                <div class="row">
                  <div class="title">ที่อยู่</div>
                  <div class="detail">
                    {{ member.address }}
                    {{ member.subdistrict }}
                    {{ member.district }}
                    {{ member.province }}
                    {{ member.zipcode }}
                  </div>
                </div>
              </div>

              <SmallTitle title="ราคาที่เสนอ (ไม่รวม Vat)" />
              <div class="data-2col">
                <div class="row">
                  <div class="title">งบประมาณ</div>
                  <div class="detail">
                    <span class="budget-output">{{ member.budget }}</span>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <!-- end: General Informatin -->

          <fieldset class="form-block">
            <div class="h-title">
              <h3>เอกสารประกอบ</h3>
            </div>
            <div class="form-container">
              <SmallTitle title="ใบเสนอราคา" />
              <MultipleFileUploader :showUploader=false type='auction_proposal' :parentId="projectId" :relateId="memberId" section="project" />

              <SmallTitle title="แผนการดำเนินการ" />
              <MultipleFileUploader :showUploader=false type='auction_plan' :parentId="projectId" :relateId="memberId" section="project" />
            </div>
          </fieldset>

          <div class="button-block">
            <!-- <button type="button" @click='testClick'>Test</button><br> -->
            <button type="submit" class="btn btn-update">
              <i class="fa fa-save fa-lg"></i> ยืนยันใบเสนอราคา
            </button>
          </div>
        </form>

      </div>
  </div>
</template>

<script>
// // @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js'

import SmallTitle           from '@/components/common/SmallTitle.vue';
import MultipleFileUploader from '@/components/file/MultipleFileUploader.vue';

export default {
  // name: 'User Edit',
  data() {
    return {
      errorClass: '',
      budget: 0,
      detail: '',
      member: {},
      memberId: 0,
      projectId: 0,
      project: {},
    }
  },
  components: {
    SmallTitle,
    MultipleFileUploader,
  },
  created() {
    this.$store.commit('setPageTitle', 'Auction Request');
    this.$store.commit('setLoaderShow', true);
    
    this.projectId = this.$route.params.id;
    this.memberId  = this.$route.params.memberId;

    // get project data
    apiRequest
      .get('/project/'+this.projectId)
      .then( (res) => {
        this.project = res.data.result;
        apiRequest
          .get( '/auction/' + this.projectId+'/'+this.memberId)
          .then( (res) => {
            this.member = res.data.result;
            console.log('Raw budget : '+this.member.budget);
          });
          this.$store.commit('setLoaderShow', false);
      });
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    
    /**
     * Approve this auction.
     * if complete redirect to Request Auction List Page
     */
    approveAuction(){
      let name = this.setMemberName();
      let conf = confirm(name+' ยืนยันการเสนอราคานี้ใช่หรือไม่ ?');

      if(conf) {
        this.$store.commit('setLoaderShow', true);
        // this.$router.go();
        apiRequest
          .post('/auction/approve', {
            project_id: this.projectId,
            member_id:  this.memberId
          })
          .then( () => {
            this.$router.push('/project/auction-request');
          });
      }
    },

    setMemberName() {
      let data = this.member;
      let fullname = data.first_name+' '+data.last_name;
      return (data.business_type==9) ? data.company_name+' ('+fullname+')' : fullname;
    },
  }
}
</script>

<style scoped>
.data-2col {margin-left: 0;}
.budget-output {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #630;
}
</style>